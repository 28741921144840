
import { computed, defineComponent, onMounted, reactive, ref, watch, watchEffect } from 'vue'
import { Actions } from '@/store/enums/StoreEnums'
import { useStore } from 'vuex'
import Swal from 'sweetalert2/dist/sweetalert2.js'

export default defineComponent({
  name: 'the-public-sessions-modal',
  props: {
    label: String,
    template: { type: Object, required: true }
  },

  components: {},
  setup (props) {
    const templateData = computed(() => props.template)
    const socket = computed(() => store.getters.getSocket)

    const closeModal = ref(null)
    const templateToDelete = ref(props.template)
    const deleteActivityLoading = ref(false)
    const modalIsShown = ref(true)
    const store = useStore()
    const isGenerateLinkLoading = ref(false)
    const disabledGenerateLink = ref(false)
    const allowPreIntroScreenSwitch = ref(1)
    const isInClassroomSwitch = ref(0)

    const formatDate = (dateString) => {
      const date = new Date(dateString)
      return date.toLocaleString()
    }

    const copyLink = (sessionUuid) => {
      const link = `${process.env.VUE_APP_IN_GAME_URI}/engage-public/${sessionUuid}`
      navigator.clipboard.writeText(link)
    }

    onMounted(() => {

    })

    const formInline = reactive({
      name: ''
    })

    const onSubmitGeneratePublicSessionLink = () => {
      isGenerateLinkLoading.value = true
      const payload = {} as any
      payload.templateUuid = props.template.activityTemplateUuid
      payload.name = formInline.name
      payload.allowPreIntroScreen = allowPreIntroScreenSwitch.value
      payload.isInClassroom = isInClassroomSwitch.value
      store.dispatch(Actions.API_PUBLIC_SESSION_CREATE, payload).then((response) => {
        navigator.clipboard.writeText(response.payload.link)
        isGenerateLinkLoading.value = false
        disabledGenerateLink.value = true
        tableData.value.unshift(response.payload.publicSession)
        setTimeout(() => {
          disabledGenerateLink.value = false
        }, 1500)
        // close the modal by clicking the x icon
      }).catch((response) => {
        Swal.fire({
          text: response.data.error.message,
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Try again',
          customClass: {
            confirmButton: 'btn fw-bold btn-light-danger'
          }
        })
      })
      console.log('formInline', props.template, formInline.name)
      console.log('submit!')
    }

    const tableData = ref([]) as any
    const parentBorder = ref(false)
    const childBorder = ref(false)
    watch(templateData, (newVal, oldVal) => {
      tableData.value = newVal.publicSessions.reverse()
    })
    watchEffect(() => {
      if (socket.value && typeof socket.value.on === 'function') {
        socket.value.on('PUBLIC_SESSION_USER_CREATED', (eventData) => {
          const { publicSession, activeUser } = eventData.payload.content

          // Find the public session in tableData.value based on publicSessionId
          const publicSessionToUpdate = tableData.value.find(
            (session) => session.publicSessionUuid === publicSession.public_session_uuid
          )

          if (publicSessionToUpdate) {
            // Check if publicSessionToUpdate has an active_users property
            if (!publicSessionToUpdate.active_users) {
              // If active_users doesn't exist, create it as an empty array
              publicSessionToUpdate.active_users = []
            }

            // Add the activeUser to the active_users array
            publicSessionToUpdate.active_users.push(activeUser)
          }
        })
      }
    })

    return {
      templateToDelete,
      deleteActivityLoading,
      modalIsShown,
      closeModal,
      isGenerateLinkLoading,
      onSubmitGeneratePublicSessionLink,
      formInline,
      disabledGenerateLink,
      parentBorder,
      childBorder,
      tableData,
      formatDate,
      copyLink,
      allowPreIntroScreenSwitch,
      isInClassroomSwitch
    }
  }
})
